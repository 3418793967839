.PanierContainer {
    width: 450px;
    max-height: 80%;
    box-shadow: -1px 1px 3px 1px #7f7f7f;
    background-color: #fff;
}

.Articles {
    max-height: 500px; 
    overflow-y: auto;
}

.Articles ul {
    padding: 5px;
    margin: 0;
    list-style-type: none;
}

.Articles li {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.Articles img {
    margin-right: 10px;
}

.Articles div {
    flex: 1;
}

.rightSideContent .voirPanier{
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    width: 90%;
}

.rightSideContent .checkout{
    color: #fff;
    background-color: var(--primary-color);
    border-radius: 8px;
    width: 90%;
}

.rightSideContent .checkout:hover{
    background-color: var(--primary-color) !important;
}

.OnhoverUser .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    height: 3rem !important;
}

.loginBtn button{
    background-color: var(--primary-color);
    border-radius: 8px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
}

.loginBtn button:hover{
    background-color: var(--primary-color) !important;
}

.OnhoverUserlinks a{
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.OnhoverUser{
    height: fit-content !important;
}

.loggedTrue a{
    font-size: 18px;
    font-weight: 600;
    color: rgb(82, 82, 82);
    border-radius: 0;
}


