.departement_content{
    background-color: rgb(255, 255, 255) !important;
    width: 100% !important;
    height: calc(100vh - 80px);
    position: fixed;
    bottom: 0%;
    left: 0;
    z-index: 9999;
}


.departement_content_list li{
    cursor: pointer;
    padding: 10px;
}

.departement_content_list {
    height: calc(100vh - 160px) !important;
    overflow-y: auto !important;
}

.departement_content_list li h3{
    margin-bottom: 0 !important;
    font-size: 22px;
    font-weight: 600;
    color: rgb(53, 52, 52);
}

.departement_content_list li:hover h3, .departement_content_list li:hover p {
    color: var(--primary-hover-color); 
    transition: all 0.2s ease;
}

.departement_content_list li:hover{
    border-left: 3px solid var(--primary-hover-color) ;
}

.onhover{
    border-left: 4px solid var(--primary-hover-color) ;
}


.departement_content a{
    color: black;
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: -.1px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.departement_content_list li p{
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 9px;
    letter-spacing: -.1px;
    color: rgba(80, 80, 80, 0.726);
}

.section_articles ul li a{
    color: rgb(0, 0, 0);
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -.1px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.section_articles ul{
    height: 70vh !important;
    overflow-y: auto !important;
}

.section_articles ul li a:hover{
    color: var(--primary-hover-color);
    transition: all .3s ease-in-out;
}


