.PiecesRechangePage .productName p{
    font-size: 20px;
    font-weight: 600;
    color: #1d1d1d;
    line-height: 1.2em;
    text-align: center;

}

.PiecesRechangePage .addToCart_price h4{
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1d;
    line-height: 2em;
}

.PiecesRechangePage .addToCart_price button{
    font-size: 18px;
    font-weight: 600;
    color: var(--primary-color);
}

.PiecesRechangePage .addToPanner{
    position: absolute ;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    width: 90%;
    padding: 5px;
    font-weight: 600;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 8px !important;
    z-index: 2;
}

@media screen and (max-width:397px) {
    .PiecesRechangePage .addToPanner{
        width: 100%;
    }
}

.PiecesRechangePage .addToPanner:hover{
    background-color: var(--primary-color) !important; 
}