.ContactUsPage {
    background-color: #fff;
}

.ContactUsPage .adress h1{
    text-align: start !important;
}

.ContactUsPage .adress .adressHeader p{
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    width: 90%;
}

.adressContent {
    font-size: 25px;
    font-weight: 600;
    color: var(--primary-color);
}

.socialMedia img{
    max-width: 80px;
    cursor: pointer;
}

.formContactUs h4{
    color: var(--primary-color);
    font-size: 25px;
    font-weight: 600;
}

.formContactUs h2{
    color: #919191;
    font-size: 35px;
    font-weight: 600;
}

.formContactUs input{
    width: 98%;
    padding: 15px 10px ;
    font-size: 20px;
    border: none;
    outline: none;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #f1b7b6
}

.formContactUs textarea{
    font-size: 20px;
    border: none;
    outline: none;
    background-color: #f5f5f5;
    padding: 20px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #f1b7b6
}

.formContactUs input:hover, .formContactUs textarea:hover{
    outline: 1px solid #fab9b986;
}

.formContactUs input:focus, .formContactUs textarea:focus{
    outline: 1px solid #fab9b986;
}

.socialMedia img:hover{
    transform: scale(1.1);
    transition: all 0.3s ease;
}

.formContactUs form button{
    color: #fff;
    background-color: var(--primary-color);
    min-width:fit-content ;
    width: 100%;
    max-width: 300px;
    font-size: 30px;
    font-weight: 600;
    padding: 10px;
    border: none;
    border-radius: 8px;
}

.formContactUs form button:hover{
    background-color: var(--primary-color) !important;
}

.bannerContact {
    width: 100%;
}

.bannerContact img{
    width: 100%;
    max-height: 700px;
}
