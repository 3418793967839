.css-10botns-MuiInputBase-input-MuiFilledInput-input{
    height: 4em !important;
}

.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 20px !important;
    font-weight: 600 !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width: 100% !important;
}

.registerbutton button , .loginbutton button{
    color: #000000;
    border-radius: 0;
    padding: 0;
}

.LoginPage .css-ahj2mt-MuiTypography-root{
    font-weight: 400;
    font-size: 24px;
}

.LoginPage .css-i4bv87-MuiSvgIcon-root{
    color: var(--primary-color) !important;
}

.LoginPage .css-batk84-MuiInputBase-root-MuiFilledInput-root::before{
    border-color: var(--primary-color) !important;
}

.LoginPage .css-batk84-MuiInputBase-root-MuiFilledInput-root::after{
    border-color: #fff !important;
}

.LoginPage input{
    width: 100%;
    border: none;
    outline: none;
    padding: 11px;
    box-shadow: 0 0 0 1px rgb(255, 99, 99);
    border-radius: 5px;
}

.formRegister input{
    width: 100%;
    padding: 11px;
    border: none;
    outline: none;
    border-radius: 5px;
    box-shadow:0 0 0 1px #000000;
}

.error-message{
    color: #ff5656;
    font-weight: 600;
}