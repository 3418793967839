.createPromocode{
    max-width: 200px !important;
    padding: 12px;
    color: #fff;
    background-color: var(--primary-color);
    border: none;
    border-radius: 8px;
    font-weight: 600;
    transition: all 0.2s ease;
}

.createPromocode:hover{
    background-color: var(--primary-hover-color) !important;
}

.ManageCoupon .css-11xur9t-MuiPaper-root-MuiTableContainer-root{
    padding: 0 !important;
}

.form-control{
    padding: 0.675rem .75rem !important;
}

.form-group label{
    color: var(--primary-color);
}

.addp:hover{
    background-color: rgba(0, 0, 255, 0.411) !important;
    transition: all 0.3s ease;
}

.removec:hover{
    background-color: var(--primary-hover-color) !important;
    transition: all 0.3s ease;
}

.editp:hover{
    background-color: rgba(0, 128, 0, 0.514) !important;
    transition: all 0.3s ease;
}

.theproductoncoupon{
    background-color: rgb(84, 245, 62);
    padding: 6px;
    color: black;
    border-radius: 4px;
    display: inline-block;
}

.deleteprocoup{
    position: absolute;
    top: -10px;
    right: -10px;
}