.managezone table .updatezone:hover{
    background-color: #5a83ff !important;
    color: #fff;
}

.managezone table .removezone:hover{
    background-color: #ff4d4d !important;
    color: #fff;
}

.addzones:hover{
    background-color: var(--primary-hover-color) !important;
    color: #fff !important;
}

.AddZones .form_add_zone input, .AddZones .form_add_zone textarea{
    width: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 0px 1px #ff7a78;
    border-radius: 8px;
    padding: 11px;
}

.savezones button{
    background-color: var(--primary-color) !important;
    color: #fff !important;
    border-radius: 8px;
    width: 100%;
}

.savezones button:hover{
    background-color:  var(--primary-color) !important;
}