.LargeNavebar, .SmallNavebar{
    background-color: var(--primary-color);
    position: sticky !important;
    z-index: 999;
    top: 0; 
    left: 0;
    right: 0;
}
.LargeNavebar .search input, .SmallNavebar .search input{
    width: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    border: 0;
    border-radius: 8px;
    color: #2c3036;
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    padding: 13px 16px;
}

.LargeNavebar a{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .5px;
    text-transform: uppercase;
}

.logo,.departement,.shorts,.search{
    display: flex;
    align-items: center;
}

.departement{
    max-width: 153px !important;
}

.search .searchIcon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
}

.offcanvas{
    max-width: 90% !important;
}  

.offcanvas-header{
    border-bottom: 1px solid #e2e1f1;
}

.label_link,.label_link_icon{
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: rgb(88, 88, 88);
    text-transform: uppercase;
}

.label_link:hover {
    color: var(--primary-color) !important;
}


.articlesCount{
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    /* background-color: rgb(253, 38, 145); */
    background-color: #000000;
}

.shorts button{
    height: 100%;
}

.panierpopup{
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    cursor: pointer;
}

.userpopup{
    box-shadow: 0px 0px 4px #6b6b6b;
    position: absolute;
    top: 100%;
    right: 0;
    cursor: pointer;
}



.search-results{
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 0px 2px #a3a3a3;
}

.search-results .search-item{
    font-size: 18px;
    font-weight: 600;
}




