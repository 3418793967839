.pagesList ul li a{
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: 13px;
    font-size: 20px;
    font-weight: 600;
    color: rgb(119, 119, 119);
    border-radius: 0;
    text-align: start;
    transition: all .1s ease;
}

.selectedPage{
    border-left: 4px solid var(--primary-color);
    color: black !important;
}