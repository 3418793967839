.inscrire_email input{
    width: 100%;
    background: none;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0;
    padding: 14px;  
    border-radius: 8px;
    border: 1px solid var(--primary-color);
    outline: none;  
}

.inscrire_email button{
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    padding: 18px 0;
    border: none;
    color: #fff;
    cursor:pointer;
}

.footer h4{
    color: #000000 ;
    font-weight: 600 !important;
    font-size: 20px;
    margin-bottom: 30px !important;
}



.Suivez_nous a span,.footer_links p{
    font-size: 20px;
    font-weight: 400;
    color: #666;
    line-height: 28px;
}