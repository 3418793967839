*{
    box-sizing: border-box !important;
    list-style: none;
    /* box-shadow: none !important; */

  }




  body{
    background-color: #ffffff !important;
  }

a{
    text-decoration: none !important;
  }

  :root {
    /* --primary-color: #df3a37; */
    /* --primary-color: #507f37; */
    --primary-color: #000000;

    /* --primary-hover-color: #ec451fb4; */
    /* --primary-hover-color: #3b6b43; */
    --primary-hover-color: #3b6b43;
  }

  .css-1yxmbwk:hover {
      background-color: rgba(0, 0, 0, 0.04);
  }

  /* Customizes the whole scrollbar */
::-webkit-scrollbar {
  width: 6px; /* width of the vertical scrollbar */
  height: 6px; /* height of the horizontal scrollbar */
}

/* Customizes the track of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
}

/* Customizes the handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background: var(--primary-hover-color); /* color of the thumb */
  border-radius: 6px; /* rounded corners on the thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color); /* color of the thumb when hovered */
}


.section_title{
  font-size: 36px;
  color: var(--primary-color);
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.slider-container{
  overflow: hidden;
  padding: 15px;
}

.slick-dots li button:before{
  font-size: 14px !important;
}

.slick-dots{
  height: 100px !important;
}

.card,.card-image{
  border: none !important;
  box-shadow: none !important;

}

.linkToProductPage{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}