.Product {
  margin-top: 50px;
  overflow-x: hidden;
}
.image-container{
    overflow: hidden;
}
.magnify-container {
  margin-left: 20px;
}

.magnify-container img {
  max-height: 600px;
}
.thumbnail {
  width: 100px;
  height: 100px;
  cursor: pointer !important;
  margin-bottom: 0.5rem;
}
.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}


.magnify-container img + div {
    display: none !important;
}

.product_name h3, .product_price h3{
    font-size: 24px;
    color: #000;
    font-weight: 400;
    line-height: 1.2;
}

.Product .product_description .securite .btn_securite{
    border-radius: 8px;
    width:  150px;
    padding:15px 10px;
    border: 1px solid rgb(177, 177, 177);
    font-size: 18px;
    font-weight: 600;
}

.chosen{
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
}

.btn_Addcart {
    border-radius: 8px !important;
    color: #fff !important;
    border: none;
    background-color: var(--primary-color) !important;
    padding: 11px !important;
    font-weight: 600;
    font-size: 17px !important;
    width: fit-content;
}

.btn_Addcart:hover{
  background-color: var(--primary-hover-color) !important;
  transition: all 0.3s ease;
}

.section_tabs .tabs_desktop ul li{
    font-size: 18px;
    font-weight: 600;
    color: #707070;
    line-height: 1.2em;
    cursor: pointer;
    border-bottom: 3px solid #bdbdbd;
    padding: 10px 20px;
}

.chosen_tab{
    color: var(--primary-color) !important;
    border-bottom: 3px solid var(--primary-color) !important;
}

.Product .relatedProductsContent{
  width: 100%;

}

.relatedProductsContent .card .card-body .productName p{
  font-size: 18px;
  font-weight: 600;
  color: #919191;
  line-height: 1.2em;
  width: 80%;
  text-align: center;
}

.Product .section_one .securite .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover{
  background-color: none !important;
}

.Product .section_one .qtt_addCart .qtt button{
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: 30%;
  border: none;
}

.Product .section_one .qtt_addCart .qtt button:hover{
  background-color: var(--primary-hover-color);

}

.relatedProductsContent .card .card-body .addToCart button{
  border-radius: 0;
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2em;
  padding: 0 ;
}

.disabled{
  background-color: #000000 !important;
}


.relatedProductPrice p{
  font-size: 18px;
  font-weight: 600;
}