.Slide img{
    object-fit: cover;
    height: 100%;
    object-position: bottom;
    /* min-height: 230px; */
}

.videoBanner{
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: center;
}