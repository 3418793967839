.DescriptionComponent .text_description p{
    font-size: 18px ;
    font-weight: 400;
    line-height: 1.2em;
    color: rgb(102, 102, 102);
}

.FicheTechniqueComponent .btnDownload button{
    color: #fff;
    background-color: black;
    width: 100%;
    border-radius: 8px;
    padding: 20px 15px;
    font-size: 20px;
    font-weight: 600;
}

.FicheTechniqueComponent .btnDownload button:hover{
    color: #fff !important;
    background-color: black !important;
}
