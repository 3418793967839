.DataPersonnelles .contentInfo ul li{
    display: flex;
    align-items: center;
    margin: 10px;
}
.DataPersonnelles .contentInfo ul li .label{
    width: 30%;
}

.DataPersonnelles .contentInfo ul li .value{
    width: 70%;
}

.DataPersonnelles .contentInfo ul li span{
    font-size: 20px;
    font-weight: 600;
    color: rgb(107, 107, 107);
}
.DataPersonnelles .btnmodifier{
    display: flex;
    justify-content: end;
}
.DataPersonnelles .btnmodifier button{
    background-color: var(--primary-color);
    border-radius: 8px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    width: fit-content;
}

.DataPersonnelles .btnmodifier button:hover{
    background-color: var(--primary-color) !important;
}

.md-80 {
    width: 100%; /* Default to 100% for small screens */
}

@media (min-width: 921px) {
    .md-80 {
        width: 70%; /* Set to 80% for medium screens and larger */
    }
}

.modifierInfo{
    background-color: #f8f9fc;
    box-shadow: 0px 0px 2px #a19f9f;;
}
.DataPersonnelles .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    height: 3.4em !important;
    background-color: #fff;
}

.DataPersonnelles .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.DataPersonnelles .css-1d3z3hw-MuiOutlinedInput-notchedOutline:hover,
.DataPersonnelles .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border:3px solid #000000 !important;
}

.DataPersonnelles .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    font-size: 20px;
    color: var(--primary-color);
    font-weight: 400;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 18px;
    color: #000000 !important;
    font-weight: 600 !important;
}

.modifierInfo form button{
    border-radius: 8px;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.modifierInfo form button:hover{
    background-color: var(--primary-color) !important;
}

.annulermodification{
    color: var(--primary-color) !important;
    font-size: 18px !important;
}





