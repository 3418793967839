.search-dropdown {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    box-shadow: 0px 4px 6px rgba(126, 126, 126, 0.1);
    z-index: 100;
    border: 1px solid rgb(187, 187, 187);
    padding: 10px;
}

.search-item {
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.search-item:last-child {
    border-bottom: none;
}


.OnhoverUser input{
    width: 100%;
    border: none;
    outline: none;
    /* box-shadow: 0 0 0 1px rgb(255, 85, 85); */
    box-shadow:0 0 0 1px #000000;
    border-radius: 5px;
    padding: 10px;
}

.departement button:hover{
    background-color: #ec441f73 !important;
}

.point{
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

