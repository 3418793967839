.css-az8st9-MuiDataGrid-root .MuiDataGrid-cellContent {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #5f5f5f !important;
}


.manageImages img{
    width: 150px;
    aspect-ratio: 1;
    margin: 8px;
    border: 2px solid rgb(156, 156, 156);
}

.gestion_Produit .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 90% !important;
    height: 80% !important;
}

.css-1fmy1zk{
    border: 3px dashed var(--primary-color) !important;
}

.removeimgbtn{
    position: absolute !important;
    top: 0%;
    right: 0;
    z-index: 3;
}
