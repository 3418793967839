.FilterSection{
    width: 100%;
    border-radius: 8px;
}

.ProductsSection{
    width: 100%;

}

.RobotsPage .ProductsSection .card-body .productName p{
    font-size: 14px;
    font-weight: 600;
    color: #919191;
    line-height: 1.2em;
    width: 100%;
    text-align: center;
    word-spacing: 1px;
}

.RobotsPage .addToCart_price h4{
    font-size: 16px;
    font-weight: 600;
    color: #919191;
    line-height: 2em;
}

.RobotsPage .addToCart_price button{
    z-index: 2;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover{
    background-color: #ffffff00 !important;
}

 .FilterSection .section_title{
    font-size: 20px;
    text-align: start;
 }

.FilterSecurite button{
    border-radius: 8px;
    width:  fit-content;
    padding:8px;
    border: 1px solid rgb(177, 177, 177);
    font-size: 18px;
    font-weight: 600;
}


.secure-label {
    background-color:var(--primary-color);
    color: white;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
}
