.GuideUtilisation .iconsleft img,.GuideUtilisation .iconsright img{
    width: 100px;
    aspect-ratio: 1;
    display: block;
    margin-bottom: 200px;
}

.GuideUtilisation .GuideUtilisationContent p{
    font-size: 19px;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 1.7;
    text-align: justify;
    color: #333333;
}

.GuideUtilisation ul {
    list-style: square !important;
    
}

.GuideUtilisation .container-xl, .container-xxl {
    max-width: 1500px !important;
}
