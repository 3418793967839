.adminDashboard .general_overview .card,
.Evolution_ventes,.Vente_par_ville, 
.Best_sellersVente_par_ville, 
.performance_graphs .Ventes_par_categorie, .Moyen_paiement{
    border-radius: 4px;
    box-shadow: 0 3px 5px rgba(0,10,10,.05);
}

.adminDashboard .general_overview .card .stats-tile{
    padding: 1.5rem 1.5rem;
}

.adminDashboard .general_overview .card .stats-tile .sale-icon{
    height: 60px;
    width: 60px;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    text-align: center;
    margin-right: 15px;
}

.adminDashboard .general_overview .card .stats-tile .sale-details h3{
    font-size: 2rem;
    color: var(--primary-color);
    font-weight: 700;
}

.adminDashboard .general_overview .card .stats-tile .sale-details p{
    font-size: 18px;
    font-weight: 600;
}