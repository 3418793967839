
.section_icons_laivraison img{
    width: 25%;
}





.content_first_section h4{
    line-height: 1.2;
    color: black;
    font-size: 15px;
    margin-bottom: 0;
}

.content_first_section p{
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin-bottom: 0;
}

.content_nosRobot {
    overflow-x: hidden;
}

.second_section_decouvrez .textcontent{
    font: 600 16px / 30px "Roboto Slab", Helvetica, sans-serif !important;
    text-align: start;
    margin: 20px 0;
    color: #666;
    letter-spacing: 1px;
    margin-top: 55px;
    max-width: 600px;
}

.section2background{
    background: url('../../public/images/parallex-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow-x: hidden;
}

.second_section_decouvrez h2{
    font: italic 600 40px / 46px "Dancing Script", Helvetica, sans-serif !important;
    text-align: start;
    text-transform: capitalize;
    color: var(--primary-color);
}

@media screen and (min-width: 768px) {
    .first_section_video {
        width: 80% !important;
    }
}




.selection_moment_content img:hover{
    transform: scale(1.01);
    transition: all 0.3s ease-in-out;
}

.third_section_nosRobot .section_title{
    font: 700 40px / 42px 'Philosopher', Helvetica, sans-serif;
    text-align: center;
    text-transform: capitalize;
    display: inline-block;
    float: none;
    margin: 0 0 20px;
    position: relative;
    vertical-align: top;
    width: 100%;
    cursor: pointer;
    padding: 0 0 52px;
}

.produitType .buttontypeproduct{
    border-radius: 8px;
    border: none;
    outline: 1px solid #000000;
    padding: 16px;
    width: 250px;
    background-color: #fff;
    font: 600 18px / 20px Roboto Slab, Helvetica, sans-serif;
    text-transform: capitalize;
    color: rgb(36, 36, 36);
    transition: all 0.2s linear;
    cursor: pointer;
}

.produitType .buttontypeproduct:hover{
    background-color: var(--primary-color);
    color: #fff;
}

.chosenType{
    background-color: var(--primary-color) !important;
    color: #fff !important;
}

.content_nosRobot .css-18lrjg1-MuiCircularProgress-root{
    width: 70px !important;
    aspect-ratio: 1 !important;
}

.marques_carousel .slick-track{
    display: flex !important;
    align-items: center !important;
}

.marques_carousel .slick-dots{
    bottom:  -88px;
}

.remisecard{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0%;
    left: 54%;
    z-index: 9 !important;
}

.remisecard p{
    font-weight: 700;
    color: #fff;
    font-size: 19px;
}

.price_oldprice span{
    font-size: 20px;
    font-weight: 700;
    display: block;
    color: var(--primary-color);
}

.old-price{
    text-decoration: line-through;
    color: #696969;
    font-size: 18px;
    font-weight: 500;
}


.out-of-stock-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 0, 0, 0.7);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .disabled-product {
    opacity: 0.5;
    pointer-events: none;
  }
  