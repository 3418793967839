.GestionAdmins{
    background-color: #fbfbfb;
}

.nbrAdminsbox,.addAdminbox{
    background-color: #ffffff;
    width: 220px;
    aspect-ratio: 2;
    border-radius: 8px;
}

.nbrAdminsbox p,.addAdminbox p{
    font-size: 20px;
    font-weight: 600;
    color: var(--primary-color);
    text-align: center;
}

.tableOfAdmins .css-275udh-MuiTableCell-root.MuiTableCell-head {
    background-color: #cfcfcf !important;
    color: black !important;
}

.saveadmin:hover{
    background-color: var(--primary-hover-color) !important;
}

.EditAdmin .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    height: 3.4em !important;
}

.EditAdmin .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    margin: 15px 0;
}