.blogs .text_And_img p{
    font-size: 20px;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 1.7;
    text-align: justify;
}

.text_And_img img{
    width: 100%;
}

.blogs .sectionContent .imgGif img{
    width: 100%;
    height: auto;
    max-height: 700px;
}

