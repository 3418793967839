.status_command_link .icon_status_command{
    color: #969799 ;
    font-size: 40px;
    margin-bottom: 10px;
}

.status_command_link p{
    color: #969799;
}

.icon_label{
    cursor: pointer;
}

.icon_label:hover .icon_status_command,
.icon_label:hover p {
    color: var(--primary-color) !important;
    transition: all 0.3s ease;
}

.article_name_command p{
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #818181;
    display: -webkit-box!important;
    font-size: 18px;
    max-height: 90px;
    overflow: hidden;
    width: 100%;
    line-height: 25px;
    font-weight: 600;
    text-align: start;
}


.articles_command:last-child{
    border-bottom: none !important;
}

.article_name_command .price{
    font-size: 20px;
    font-weight: 600;
    color: var(--primary-color);
}
.article_name_command .status{
    font-size: 20px;
    font-weight: 600;
    color: var(--primary-color);
}

.statusChosen{
    color: var(--primary-color) !important;
}