.middle_content .searchIcon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.middle_content div{
    border: 3px solid var(--primary-color);
}

.middle_content input{
    padding-left: 20px;
    color: #fff;
}

.offcanvas{
    max-width: 60%;
    border: none !important;
    outline: none !important;
    box-shadow: none;
}

.offcanvas-backdrop.show{
    opacity: 0;
}

@media screen and (min-width:720px) {
    .offcanvas{
        max-width: 30% !important;
    }
}


.list_subject{
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgb(66, 66, 66);
    font-size: 20px;
    font-weight: 600;
    margin-right: 8px;
}




