.PannierPage .PannierContnet{
    width: 100%;
}
.PannierPage .ResumeCommande{
    width: 100%;
    height: 100%;
    background-color: #e9e9e949;
}

.ResumeCommande h3{
    color: #919191;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.PannierContnetBody .pannier_text .name_article_pannier{
    font-size: 20px;
    font-weight: 600;
    color: #919191;
}

.PannierContnetBody .pannier_text .prix p{
    font-size: 20px;
    font-weight: 600;
    color: #919191;
}

.PannierContnetBody{
    box-shadow: 0px 0px 1px 3px #efefef;;
}

.Increase_decrease_quantity button{
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 7px;
    border-radius: 30%;
}

.PannierPage .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover{
    background-color: var(--primary-color) !important;
}

.PannierPage .Increase_decrease_quantity .quantity{
    font-size: 20px;
    font-weight: 600;
}

.pannier_text .remove_pannier_page{
    background-color: var(--primary-color);
    border: none;
    padding: 7px;
    border-radius: 30%;
}

.ResumeCommande .section_title{
    font-size: 30px;
}

.Resumeheader p, .livraison p, .codePromo p, .total p{
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2em;
    color: var(--primary-color);
    letter-spacing:1px;
}

.codePromo div{
    width: 90%;
    height: 45px;
    box-shadow: 0px 0px 1px 0px #3f3f3f;
}

.codePromo div input{
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: 600;
    border: none;
    outline:none;
}

.codePromo button{
    background-color: #fa7474;
    color: #fff;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600;
}

.checkoutButton button{
    background-color: #fa7474;
    color: #fff;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600;
}

.line{
    width: 100%;
    height: 2px;
    background-color: #d5d5d5;
    border-radius: 8px;
}

.backToStore{
    color: var(--primary-color);
}

/* ################################################################## */
.formCheckoutTitle{
    background-color: #f0f0f0;
}

.formCheckoutTitle h2{
    font-size: 25px;
    font-weight: 600;
    color: #575757;
}

/* .formCheckout .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    height: 4em !important;
} */

.formCheckoutinputs input{
    width: 100%;
    padding: 11px;
    border: none;
    outline: none;
    box-shadow: 0 0 0 1px #ff9292;
    border-radius: 5px;
}

.formCheckoutinputs input:focus{
    box-shadow: 0 0 0 1px #ff5555;
}




.methodPayment label{
    font-size: 23px;
    font-weight: 600;
    color: #666666;
}

.methodPayment input[type='radio'] {
    margin-right: 10px;
    width: 15px; /* Adjust width */
    height: 15px; /* Adjust height */
    transform: scale(1.5); /* Adjust the scale to change size */
    border-radius: 50%; /* Make it round */
    cursor: pointer; /* Change cursor to pointer */
    position: relative; /* Position relative for custom checkmark */
}


.methodPayment input[type='radio']:checked::before {
    content: '';
    display: block;
    width: 10px; /* Size of the inner circle */
    height: 10px; /* Size of the inner circle */
    border-radius: 50%; /* Make the inner circle round */
    position: absolute; /* Position absolute for centering */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the inner circle */
}

.methodPayment ul li{
    min-height: 70px !important;
    border-bottom: 2px solid var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.methodPayment li:last-child {
    border-bottom: none;
}

.methodPayment{
    background-color: #f8f6f8;
}

.methodPayment .RIB p{
    font-size: 18px;
    font-weight: 600;
    color: #666666;
}

.passercommande button{
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    background-color: var(--primary-color);
}

.passercommande button:hover{
    background-color: var(--primary-hover-color);
    transition: all 0.3s ease;
}

.qtt_remove .Increase_decrease_quantity button:hover{
    cursor: pointer;
    background-color: var(--primary-hover-color) !important;
}

.qtt_remove .remove_pannier_page:hover{
    cursor: pointer;
    background-color: var(--primary-hover-color) !important;
}