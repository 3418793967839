

.form-adress input,.form-select{
    box-shadow: 0px 0px 2px #cbcbcb;
    padding: 12px;
    width: 100%;
    margin: 8px 0;
    border-radius: 0;
    border: none;
    outline: none;
}

.form-adress input:focus,.form-select:focus{
    outline: none;
    box-shadow: 0px 0px 2px 0px #9b9b9b;
    border: none;
}



.send_adresse{
    font-weight: 700;
    margin-right: 10px;
    min-width: 230px;
    padding: 0 20px;
    background-color: var(--primary-color);
    border: none;
    color: #fff;
    font-size: 18px;
    height: 48px;
    line-height: 48px;
    outline: none;
    text-align: center;
    width: auto;
}

.annuler_adresse{
    font-weight: 700;
    margin-right: 10px;
    min-width: 230px;
    padding: 0 20px;
    width: auto;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.87);
    color: rgba(0,0,0,.87);
    line-height: 46px;
}

@media screen and (max-width:540px) {
    .annuler_adresse,.send_adresse{
        width: 100% !important;
    }
}

.adresses_saved span{
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #999;
    width: 50%;
    font-weight: 400;
}

.adresses_saved p{
    -webkit-line-clamp: 2;
    display: -webkit-box!important;
    height: 38px;
    line-height: 19px;
    margin-bottom: 10px;
    overflow: hidden;
    
}

.adresses_saved .fourth_line a,.adresses_saved .fourth_line button{
    color: var(--primary-color);
    font-size: 16px;
    line-height: 15px;
    margin-right: 18px;
    font-weight: 400;
    border: none;
    background-color: transparent;
}

.adresses_saved .fourth_line a:hover,.adresses_saved .fourth_line button:hover{
    text-decoration: underline;

}

.adresses_saved .adresses_saved_columns{
    box-shadow: 0px 0px 2px 0px #cbcbcb;
    background: #fff;
    padding: 20px;
}

.adresses_saved_columns i{
    position: absolute;
    right: 20px;
    cursor: pointer;
}

.adresses_saved_columns .is_default{
    position: absolute;
    bottom: 0;
    right: 0;
}

.adresses_saved_columns i:hover{
    transform: scale(1.1);
    transition: all 0.3s ease;
}